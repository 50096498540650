export default {
    init() {
        // JavaScript to be fired on all pages

        // // Jquery-match-height
        // $(function() {
        //     $('.example').matchHeight();
        // });

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        $('.home__navigation ul').matchHeight();
        $('.block__item--title').matchHeight();
        $('.block__item--content').matchHeight();
        $('.block__item--content ul').matchHeight();
        $('.match-height').matchHeight();

        $('#gform_submit_button_11').addClass('btn-primary');
        $('#gform_11 .gform_button').addClass('btn-primary');
        $('.footer__block--form input[type="submit"]').addClass("btn-primary");

        $('.usp__row ul').slick({
            speed: 900,
            infinite: true,
            touchThreshold: 100,
            slidesToShow: 5,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });

        $('.sidebar__gallery--big').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.sidebar__gallery--small',
        });
        $('.sidebar__gallery--small').slick({
            infinite: false,
            swipeToSlide: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.sidebar__gallery--big',
            dots: false,
            focusOnSelect: true,
        });

        $(".form__material_wrapper .gfield").click(function () {
            $(this).addClass("form__label-selected");
        });
        $('.form__material_wrapper .gfield input').focus(function () {
            $(this).parent().parent().addClass('form__label-selected');
        });
        $('.form__material_wrapper .gfield input').blur(function () {
            if ($(this).val()) {
                $(this).parent().parent().addClass('form__label-selected');
            } else {
                $(this).parent().parent().removeClass('form__label-selected');
            }
        });
        $('.form__material_wrapper .gfield input').each(function () {
            var text_value = $(this).val();
            if (text_value !== '') {
                $(this).parent().parent().addClass('form__label-selected');
            }
        });

        $('.form__material_wrapper .gfield textarea').focus(function () {
            $(this).parent().addClass('form__label-selected');
        });
        $('.form__material_wrapper .gfield textarea').blur(function () {
            if ($(this).val()) {
                $(this).parent().parent().addClass('form__label-selected');
            } else {
                $(this).parent().parent().removeClass('form__label-selected');
            }
        });
        $('.form__material_wrapper .gfield textarea').each(function () {
            var text_value = $(this).val();
            if (text_value !== '') {
                $(this).parent().parent().addClass('form__label-selected');
            }
        });

        // $('.ginput_container .medium').each(function () {
        //     $(this).parent().parent().addClass('col-12 col-md-6');
        // });

        // $('.ginput_container .large').each(function () {
        //     $(this).parent().parent().addClass('col-12');
        // });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
