// import external dependencies
import 'jquery';

// Import everything from autoload
import "./autoload/_bootstrap.js"

import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'slick-carousel/slick/slick.min';
import 'jquery-match-height/jquery.matchHeight';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import modernizr from './routes/modernizr';
import menu from './routes/menu';
import home from './routes/home';
import templateContact from './routes/templateContact';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    modernizr,
    menu,
    // Home page
    home,
    // About Us page, note the change from about-us to aboutUs.
    aboutUs,
    templateContact,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
