export default {
    init() {
        // JavaScript to be fired on the home page
        /* eslint-disable */

        $('.home__projecten--slider').slick({
			speed: 900,
			infinite: true,
			touchThreshold: 100,
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			dots: true,
			autoplay: true,
			prevArrow: $('.home__projecten--slider__item--prev'),
			nextArrow: $('.home__projecten--slider__item--next'),
		});

		$('.home__occasions--slider').slick({
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			swipeToSlide: true,
			touchThreshold: 100,
			responsive: [
				{
					breakpoint: 1450,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 575,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		/* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
